<template>
  <div class='verifying-bank-account-main-wrapper'>
    <a-row style='display: unset'>
      <a-col>
        <div style='margin-top: 100px'>
          <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
          <div>
            <div class='verifying-bank-account-title'>
              Verifying your bank account
            </div>
            <div class='verifying-bank-account-title'>
              <img src='../../../assets/accountVerified.png' />
            </div>
            <div style='font-weight: 700'>
              We will add 1 rupee in your Bank Account to verify
            </div>
            <div style='margin-top: 10px'>
              This may take upto 60 seconds. Please do not close this page.
            </div>
            <div
              v-if="accountVerified"
              @click='onClickContinue'
              style='cursor: pointer'
              class='pan-details-verified-message-wrapper'
            >
              <span  class='pan-details-verified-text'>
                <span style='margin-right: 16px'>
                  <check-circle-two-tone two-tone-color='#52c41a' />
                </span>
                <span>Congratulations! Your Bank Account Verified</span>
              </span>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import {
  onMounted,
  ref,
  computed,
} from 'vue';
import { CheckCircleTwoTone } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';

export default {
  components: {
    CheckCircleTwoTone,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const accountVerified = ref(false);
    const routeName = computed(
      () => store.getters['onboardingStore/getRouteName'],
    ).value;
    onMounted(() => {
      window.scrollTo(0, 0);
      const waitMessage = null;
      const waitDescription = 'Please wait while we verify your bank account';
      Operations.notificationInfo(waitMessage, waitDescription);
      services
        .getStages()
        .then((response) => {
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
        })
        .catch(() => {
        });
      services
        .bankAccountVerificationStatus()
        .then((response) => {
          accountVerified.value = true;
          setTimeout(() => {
            store.dispatch('onboardingStore/updateStage', response.data.data.stage);
          }, '5000');
        })
        .catch((error) => {
          setTimeout(() => {
            if (routeName === '/uploaded/cheque') {
              const systemError = Operations.axiosErrorHandler(error);
              const message = systemError.status;
              const description = systemError.message
                ? systemError.message
                : '';
              Operations.notificationError(message, description);
              router.push('/onboarding/uploaded/cheque');
            }
            if (routeName === '/bank/details') {
              const systemError = Operations.axiosErrorHandler(error);
              const message = systemError.status;
              const description = systemError.message
                ? systemError.message
                : '';
              Operations.notificationError(message, description);
              router.push('/onboarding/bank/details');
            }
          }, '5000');
        });
    });
    const onClickContinue = () => {
      router.push('/onboarding/upload/selfie');
    };
    return {
      onClickContinue,
      accountVerified,
      routeName,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/verifyingBankAccount.scss';
</style>
